import styled from 'styled-components';
const MediumFont = 'Rubik-Medium';
const RecipeMainContainer = styled.div`
  width: 100%;
`;
const RecipeGrid = styled.div`
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  display: flex;
  padding: ${({ padding }) => padding ? '0px' : '0 20px'};
  justify-content: space-between;
  @media (max-width: 700px){
    margin: 0px;
  }
`;
const RecipeCard = styled.div`
  width: calc((100% - 30px) / 3);
  width: calc((100% - 50px) / 3);
  width: ${({ width }) => width ? width : 'calc((100% - 50px) / 3)'};
  border-radius: 6px;
  display: flex;
  display: grid;
  display: ${({ display }) => display ? display : 'block'};
  position: relative;
  // justify-content: center;
  align-items: center;
  height: auto;
  margin-bottom: 0px;
  margin-bottom: ${({ margin }) => margin ? margin : '0px'};
  position: relative;
  @media (max-width: 1260px){
    width: calc((100% - 20px) / 2);
  }
  @media (max-width: 700px){
    width: calc((100% - 40px) / 2);
    margin: 10px;
  }
  @media (max-width: 500px){
    width: 100%;
  }
  .bg-opacity {
    position: absolute;
    height: 235px;
    width: 100%;
    top: 0;
    left: 0px;
    background: rgba(0, 0, 0, 0.329545);
    border-radius: 6px;
    @media (max-width: 400px){
     width: 100%;
   }
  }
  .recipeName{
    position: absolute;
    font-family: ${MediumFont}
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    text-transform: capitalize;
    top: 20px;
    left: 30px;
    max-width: 298px;
  }
  >img{
    position: absolute;
    z-index: 2;
    top: 15px;
    right: 20px;
    width: 20px;
    height: 30px;
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  display: inline-block;
  position:relative
  height: 235px;
  height: auto;
  max-width: 399px;
  >div{ 
    >img {
    cursor: default
    height: 250px;
    border-radius: 6px;
    width: 100%;
  }
  div{
    >img {
    cursor: default
    height: 250px;
    border-radius: 6px;
    width: 100%;
  }

  }
  .educateImageBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 250px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0 0;
    .getStarted {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      width: 122px;
      min-height: 37px;
      background-color: #FD7175;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 6px;
      color: white;
      padding: 5px 0px;
      text-align: center;
    }
  }
}
  @media(max-width: 400px){
    width: 100%;
    >div{ >img{
      width: 100%;
    }}
  }
`;
export {RecipeGrid, RecipeCard, RecipeMainContainer, ImageContainer};