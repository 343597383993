import React, { Component } from 'react';
import { RecipeCard, ImageContainer } from './styles';
import { ImageUrl } from '../../utils/constants';
import { ImageUrlNew } from '../../utils/constants';
import PropTypes from 'prop-types';
import { Card, CardContainer, ChampionsCard, EducateContent, Layout, Title } from "../AdventureCategories/styles";
import ThumbnailNew from '../ThumbnailNew';
import isNull from "lodash/isNull";
import { NoComments } from "../SocialFeeds/styles";
import { EngagementContainer, SplitEngagementSection } from "../EngagementSection/styles";
import { Button } from "./RecipeDetails/styles";
import ImageThumbnail from "../ImageThumbnail";
import { withTranslation } from 'react-i18next';
import AdventurePointDashBoard from '../AdventurePointDashBoard';
import { CircleContainer, Circle, Tick } from '../Journeys/styles';
import RecommendedRecipe from './recommendedRecipe';
import { AdminButtons } from '../Article/styles';
const iconPath = {
  svg: 'M8,3.44c-4.32,-7.48 -15.61,3.63 0,12.56c15.61,-8.93 4.32,-20.03 0,-12.56z',
  fill: '#ef6827'
};
class Recipes extends Component {
  constructor() {
    super();
    this.state = {
      onHover: -1,
      temp: 0,
      tempNew: 0,
      save: 0
    }
  }

  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  changeEducationDashboard = (name) => {
    const { history } = this.props;
    history.push(`/education/recipes/category/${name.toLowerCase()}`);
  };
  seeMore = () => {
    this.setState({
      temp: !this.state.temp,
      tempNew: !this.state.tempNew
    })
  }

  saveImage = () => {
    this.setState({
      save: !this.state.save,
    })
  }

  getRecipesContainer = (categories) => (
    <div>
      <div style={{ "display": "flex", "flex-wrap": "wrap", "marginBottom": "0px", "background": "white", "padding": "2px 2px 0px 2px", "border-radius": "6px" }}>
        {
          categories.map((data, index) => (
            (index < 10 ?
              <ChampionsCard key={index} grid={5} >
                <Card adventurePage onClick={() => this.props.history.push(`/education/recipes/category/${data['recipe_tag'].toLowerCase()}`)} border={((index === 0) || (index % 5 === 0)) ? 1 : 0}>
                  <div className="video-category" onMouseEnter={() => this.onEnterMouse(data.id)} onMouseLeave={() => this.onLeaveMouse()}>
                    <ThumbnailNew
                      src={`${ImageUrl}/${data.image}`}
                      alt={data['recipe_tag']}
                    />
                  </div>
                  <div className='content'>
                    <EducateContent>
                      <div className='work'>
                        <div>
                          {this.props.t(data['recipe_tag'])}
                        </div>
                      </div>
                    </EducateContent>
                  </div>
                </Card>
              </ChampionsCard> : null)
          ))
        }
        {
          categories.map((data, index) => (
            (index >= 10 && this.state.temp ?
              <ChampionsCard key={index} grid={5}>
                <Card adventurePage onClick={() => this.props.history.push(`/education/recipes/category/${data['recipe_tag'].toLowerCase()}`)} border={((index === 0) || (index % 5 === 0)) ? 1 : 0}>
                  <div className="video-category" onMouseEnter={() => this.onEnterMouse(data.id)} onMouseLeave={() => this.onLeaveMouse()}>
                    <ThumbnailNew
                      src={`${ImageUrl}/${data.image}`}
                      alt={data['recipe_tag']}
                    />
                  </div>
                  <div className='content'>
                    <EducateContent>
                      <div className='work'>
                        <div>
                          {this.props.t(data['recipe_tag'])}
                        </div>
                      </div>
                    </EducateContent>
                  </div>
                </Card>
              </ChampionsCard> : null)
          ))
        }
      </div>
      <div style={{ "width": "100%", "margin-top": "0px", "background": "rgba(156,156,156,0.1)", "margin-bottom": "25px", "border-radius": "6px" }}>
        {this.state.temp ?
          <Button onClick={() => this.seeMore()}>{this.props.t("See Less")}   <img src="/public/images/NewDashboardV2/downarrownew.png" style={{ "transform": "rotate(180deg)" }} /></Button> :
          <Button onClick={() => this.seeMore()}>{this.props.t("See More")}   <img src="/public/images/NewDashboardV2/downarrownew.png" /></Button>
        }
      </div>
    </div>
  );
  getRecommendedRecipes = (viewRecipesData) => {
    const { onHover } = this.state;
    return (
      viewRecipesData.map((nutrition, index) => (
        <RecipeCard key={index} width={'calc((100% - 30px) / 2)'} >
          {this.state.save !== 0 ?
            <img src="/public/images/NewDashboardV2/ribbonColor.png" onClick={() => this.saveImage(index)} /> :
            <img src="/public/images/NewDashboardV2/ribbon.png" onClick={() => this.saveImage(index)} />
          }
          <ImageContainer>
            <div onMouseEnter={() => this.onEnterMouse(nutrition.id)} onMouseLeave={() => this.onLeaveMouse()}>
              <ImageThumbnail src={`${ImageUrlNew}/${nutrition['recipe_image']}`} alt={"recipe"} />
              {onHover === nutrition.id &&
                <div className='educateImageBackground' >
                  <div className="getStarted" onClick={() => this.changeEducationDashboard(nutrition.category_name)}>{this.props.t("Start Cooking")}</div>
                </div>
              }
            </div>
            <div style={{ "display": "flex", "background": "white", "padding": "15px 0px" }}>
              <div style={{ "width": "70%", "margin": "auto", "fontFamily": "rubik-regular", "fontSize": "18px" }}>{this.props.t("Recipes name")}
              </div>
              <CircleContainer style={{ "margin": "auto", "margin-right": "20px" }}>
                <div className="flex">
                  <Circle borderColor="#9c9c9c">
                    <Tick />
                  </Circle>
                </div>
                <div className="quiz">
                  {this.props.t("WATCHED")}
                </div>
              </CircleContainer>
            </div>
          </ImageContainer>
        </RecipeCard>
      ))
    )
  };

  render() {
    const { recipeCategories, recommendedRecipes, favoriteRecipes, createRecipe, createCategory, history, onClickFavoriteRibbon, t } = this.props;
    const { onHover } = this.state;
    let categories = [];
    recipeCategories && recipeCategories.map((data) => {
      if (!isNull(data['image'])) {
        categories.push(data);
      }
    });
    let recommendedRecipesData = recommendedRecipes && recommendedRecipes.length>0 && recommendedRecipes.slice(0, 4);
    return (
      <Layout>
        <div>
          <EngagementContainer>

            <SplitEngagementSection gridWidth="100%" smallWidth="100%" style={{ "display": "flex" }}>
              <CardContainer style={{ "width": "67.1%" }}>
                {categories && categories.length > 0 ? this.getRecipesContainer(categories, iconPath, onHover) : <NoComments>{t("No Recipes Categories Found")}</NoComments>}
                <Title style={{ "width": "100%" }} bottomrecipe={'1px'}>{t("Featured Recipes")}</Title>
                {recommendedRecipesData.length === 0 ? <NoComments>{t("No Recommended Recipes")}</NoComments>: Array.isArray(recommendedRecipesData) && recommendedRecipesData.map((nutrition, index) =>   (
                  <RecommendedRecipe onClickFavoriteRibbon={onClickFavoriteRibbon} nutrition={nutrition} key={index} history={history} margin={"25px"} width={'calc((100% - 25px) / 2)'} />
                ))}
                <Title style={{ "width": "100%", "margin-bottom": "15px" }}>{t("Favorite Recipes")}</Title>
                {favoriteRecipes.length === 0 ? <NoComments />
                  :
                  favoriteRecipes.map((nutrition, index) => (
                    <RecommendedRecipe onClickFavoriteRibbon={onClickFavoriteRibbon} nutrition={nutrition} key={index} history={history} margin={"25px"} width={'calc((100% - 25px) / 2)'} />
                  ))
                }
              </CardContainer>
              <div style={{ "width": "32.9%", "paddingLeft": "25px", "position": "relative", "bottom": "55px" }}>
                {createRecipe &&
                <AdminButtons background={"#F6B479"} onClick={() => history.push('/education/create-recipe')}>
                  <div>
                    <img src="/public/images/NewDashboardV2/createrecipe.png" alt="back-arrow" />
                  </div>
                  <div>
                    {t("Create Recipe")}
                  </div>
                </AdminButtons>
                }
                {createCategory &&
                <AdminButtons background={"#69C2FF"} onClick={() => history.push('/education/create-recipe-category')} >
                  <div>
                    <img src="/public/images/NewDashboardV2/createjourney.png" alt="back-arrow" />
                  </div>
                  <div>
                    {t("Create Recipe Category")}
                  </div>
                </AdminButtons>
                }
                <AdventurePointDashBoard />
              </div>
            </SplitEngagementSection>
          </EngagementContainer>
        </div>
      </Layout>
    )
  }
}
Recipes.propTypes = {
  history: PropTypes.object,
  recommendedRecipes: PropTypes.array,
  role: PropTypes.string,
  recipeCategories: PropTypes.array,
  createRecipe: PropTypes.bool,
  createCategory: PropTypes.bool,
  t: PropTypes.func,
  favoriteRecipes: PropTypes.array,
  onClickFavoriteRibbon: PropTypes.func
};
export default (withTranslation()(Recipes));
