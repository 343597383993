import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImageUrlNew } from '../../utils/constants';
import { CircleContainer, Circle, Tick } from '../Journeys/styles';
import { withTranslation } from 'react-i18next';
import { RecipeCard, ImageContainer } from './styles';
import LazyImage from '../common/LazyImage/LazyImage';

class RecommendedRecipe extends Component {
  constructor() {
    super();
    this.state = {
      onHover: -1,
      save: 0,
    }
  }

  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  saveImage = () => {
    this.setState({
      save: !this.state.save,
    })
  }

  render() {
    const { nutrition, margin, display, width, history, onClickFavoriteRibbon, t } = this.props;
    const { onHover } = this.state;
    return (
      <RecipeCard  margin={margin} display={display} width={width}>
        {this.state.save !== 0 || nutrition.favorite_status ?
          <img src="/public/images/NewDashboardV2/ribbonColor.png" onClick={() => {onClickFavoriteRibbon("recipe", nutrition.id, 0, nutrition.favorite_status ? 0 : 1,0);this.saveImage()}} /> :
          <img src="/public/images/NewDashboardV2/ribbon.png" onClick={() => {onClickFavoriteRibbon("recipe", nutrition.id, 0, nutrition.favorite_status ? 0 : 1,0);this.saveImage()}} />
        }
        <ImageContainer>
          <div onMouseEnter={() => this.onEnterMouse(nutrition.id)} onMouseLeave={() => this.onLeaveMouse()} style={{ "cursor":"pointer" }}>
            <LazyImage src={`${ImageUrlNew}/${nutrition['recipe_image']}`} alt={"recipe"} onError={(ev) => {ev.target.src = '/public/images/placeholder/video-placeholder.jpg'}}/>
            {onHover === nutrition.id &&
              <div className='educateImageBackground' >
                <div className="getStarted" onClick={() => history.push(`/education/recipes/${nutrition['id']}`)}>{t("Start Cooking")}</div>
              </div>
            }
          </div>
          <div style={{ "display": "flex", "background": "white",  "border-radius":"6px", "height":"70px" }}>
            <div style={{ "width": "70%", "margin": "auto", "fontFamily": "rubik-regular", "fontSize": "18px", "text-overflow":"ellipsis", "white-space":"nowrap", "overflow":"hidden" }}>{nutrition.name}
            </div>
            <CircleContainer style={{ "margin": "auto", "margin-right": "15px" }}>
              <div className="flex">
                <Circle borderColor="#9c9c9c" checked={nutrition.view_status}>
                  <Tick checked={nutrition.view_status}/>
                </Circle>
              </div>
              <div className="quiz">
                {t("Watched")}
              </div>
            </CircleContainer>
          </div>
        </ImageContainer>
      </RecipeCard>
    );
  }
}

RecommendedRecipe.propTypes = {
  nutrition: PropTypes.object,
  history: PropTypes.object.isRequired,
  margin: PropTypes.string,
  key:PropTypes.string,
  display: PropTypes.string,
  width: PropTypes.string,
  onClickFavoriteRibbon: PropTypes.func,
  t: PropTypes.func
};

export default (withTranslation()(RecommendedRecipe));